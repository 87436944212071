import cn from "classnames";
import { createSignal } from "solid-js";

import { ArrowRight } from "#icons";
import { ImgixImage } from "#components/imgixImage";

import { layoutData } from "#layouts/layoutData";
const { items } = layoutData.navbar.filter(
  (nav) => nav.title === "Destinations",
)[0].submenu;

const nav = layoutData.navbar.filter((nav) => nav.title === "Destinations")[0];

const tagularPayload = {
  event: "ElementClicked",
  actionOutcome: "INTERNALLINK",
};

const tagularPayloadWebElement = {
  location: "HEADER",
  elementType: "LINK",
  position: "GLOBAL SUB MENU",
};

export function DestinationPanel() {
  const [activeDestination, setActiveDestination] = createSignal("Africa");
  const [subItems, setSubItems] = createSignal(
    items.find(({ title }) => title === "Africa"),
  );

  const handleNavClick = (e: MouseEvent) => {
    const target = e.currentTarget as HTMLElement;
    const text = target.getAttribute("data-title") as string;
    const data = items.find(({ title }) => title === text);
    setSubItems(data);
    setActiveDestination(text);
  };

  return (
    <>
      <div class="flex gap-x-6 w-content px-0 py-0 pr-6">
        <div
          id="destination-panel"
          class="sidebar w-[230px] shrink-0 border-r border-black-200"
        >
          {items.map(({ title: itemTitle }) => (
            <button
              data-tagular={JSON.stringify({
                ...tagularPayload,
                webElement: {
                  ...tagularPayloadWebElement,
                  text: activeDestination(),
                },
              })}
              type="button"
              class={cn(
                "block w-full text-left my-2 py-1 pl-4 @7xl:pl-6 pr-4 text-black first:mt-8 last:mb-8 hover:text-blue hover:bg-black-100",
                activeDestination() === itemTitle
                  ? "bg-blue-100 text-blue font-semibold"
                  : "",
              )}
              onClick={(e) => handleNavClick(e)}
              data-title={itemTitle}
            >
              {itemTitle}
            </button>
          ))}
        </div>
        <div class="py-8 shrink w-[450px] xxl:w-[600px]">
          <div class="flex @5xl:flex-nowrap gap-x-4 @5xl:gap-x-6 gap-y-4 @5xl:gap-y-6">
            {subItems() &&
              subItems()?.descendants?.map(({ countries, regions, cities }) => {
                const sections = [
                  {
                    descendants: countries,
                    descendantTitle: "Countries",
                  },
                  {
                    descendants: regions,
                    descendantTitle:
                      subItems()?.title === "North America"
                        ? "States"
                        : "Regions",
                  },
                  {
                    descendants: cities,
                    descendantTitle: "Cities",
                  },
                ];

                return sections.map(({ descendants, descendantTitle }) => {
                  if (Array.isArray(descendants) && descendants.length !== 0) {
                    return (
                      <div
                        class={cn(descendants.length === 3 ? "w-1/3" : "w-1/2")}
                      >
                        <p class="type-sm border-b border-black-200 font-semibold pb-1 mb-2">
                          {descendantTitle}
                        </p>
                        {descendants.map(({ title: dTitle, url }) => (
                          <a
                            data-tagular={JSON.stringify({
                              ...tagularPayload,
                              outboundUrl: url,
                              webElement: {
                                ...tagularPayloadWebElement,
                                text: dTitle,
                                name: descendantTitle,
                              },
                            })}
                            href={url}
                            class="block py-1 hover:text-blue"
                          >
                            {dTitle}
                          </a>
                        ))}
                      </div>
                    );
                  }
                  return null;
                });
              })}
          </div>
        </div>
        <div class="shrink-0 ml-auto mr-0 w-60 py-8">
          {nav.featured && (
            <article class="relative">
              <ImgixImage
                src={nav.featured.image}
                alt={`picture of ${nav.featured.title}`}
                className="rounded-md aspect-244/175"
                width={244}
                sizes="(min-width: 1024px) 244px, 175px"
                srcSet={`${nav.featured.image}?w=244 244w, ${nav.featured.image}?w=175 175w`}
                height={175}
                imgixParams={{
                  auto: "format",
                  fit: "crop",
                  w: 244,
                  h: 175,
                }}
              />
              <a
                data-tagular={JSON.stringify({
                  ...tagularPayload,
                  outboundUrl: nav.featured.url,
                  webElement: {
                    ...tagularPayloadWebElement,
                    text: nav.featured.title,
                  },
                })}
                href={nav.featured.url}
                class="card-link mt-3 mb-2 flex gap-x-2.5 items-center hover:text-blue font-semibold"
              >
                {nav.featured.title}
              </a>
              <p>{nav.featured.description}</p>
            </article>
          )}
        </div>
      </div>
      <div class="flex bg-black-100">
        <a
          data-tagular={JSON.stringify({
            ...tagularPayload,
            outboundUrl: nav.cta.url,
            webElement: {
              ...tagularPayloadWebElement,
              text: nav.cta.title,
            },
          })}
          href={nav.cta.url}
          class="flex w-[230px] shrink-0 border-r border-black-200 bg-black-100 items-center justify-between py-3 px-6 font-semibold text-black hover:bg-blue hover:text-white"
        >
          {nav.cta.title}
        </a>
        {nav.submenu?.items &&
          nav.submenu.items
            .filter(({ title }) => title === activeDestination())
            .map((item) => (
              <a
                href={item.url}
                class="flex w-full bg-black-100 gap-x-2.5 items-center justify-end py-3 px-6 font-semibold text-black hover:bg-blue hover:text-white"
                data-tagular={JSON.stringify({
                  ...tagularPayload,
                  outboundUrl: item.url,
                  webElement: {
                    ...tagularPayloadWebElement,
                    text: `More in ${item.title}`,
                  },
                })}
              >
                More in 
                {item.title === "The Caribbean" ? "the Caribbean" : item.title}
                <ArrowRight width="16" height="16" aria-hidden="true" />
              </a>
            ))}
      </div>
    </>
  );
}

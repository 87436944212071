import { withIcon } from "#icons/_withIcon";

export const CompassFill = withIcon(
  () => (
    <>
      <path d="M8 15.5C9.48336 15.5 10.9334 15.0601 12.1668 14.236C13.4001 13.4119 14.3614 12.2406 14.9291 10.8701C15.4968 9.49968 15.6453 7.99168 15.3559 6.53683C15.0665 5.08197 14.3522 3.7456 13.3033 2.6967C12.2544 1.64781 10.918 0.933503 9.46318 0.644114C8.00832 0.354725 6.50032 0.50325 5.12987 1.07091C3.75943 1.63856 2.58809 2.59986 1.76398 3.83323C0.939867 5.0666 0.5 6.51664 0.5 8C0.502118 9.98848 1.29297 11.8949 2.69904 13.301C4.1051 14.707 6.01153 15.4979 8 15.5Z" />
      <path
        d="M11.0529 4.26879L7.33585 5.31479C6.85497 5.45177 6.41699 5.70918 6.06334 6.06265C5.70969 6.41612 5.45207 6.85398 5.31485 7.33479L4.26885 11.0518C4.24781 11.1262 4.24433 11.2045 4.25867 11.2805C4.27301 11.3565 4.30479 11.4282 4.35151 11.4898C4.39822 11.5514 4.4586 11.6014 4.5279 11.6358C4.59719 11.6701 4.67351 11.6879 4.75085 11.6878C4.79653 11.6879 4.84199 11.6815 4.88585 11.6688L8.60285 10.6228C9.08373 10.4858 9.52172 10.2284 9.87536 9.87493C10.229 9.52146 10.4866 9.08361 10.6239 8.60279L11.6689 4.88579C11.693 4.80024 11.6939 4.7098 11.6715 4.62379C11.649 4.53778 11.6041 4.4593 11.5412 4.39645C11.4783 4.33359 11.3999 4.28863 11.3139 4.26619C11.2278 4.24375 11.1384 4.24465 11.0529 4.26879Z"
        fill="white"
      />
      <circle cx="8" cy="8" r="1" />
    </>
  ),
  {
    viewBox: "0 0 16 16",
  },
);
// Change log: previous name: `Adventure`
// Change log: previous name: `WTTN: Outdoors` (requires prop mono)
export const CompassFeature = withIcon(
  ({ primary, accent }) => (
    <>
      <path
        d="M28.3345 11.7186L16.8545 16.3396C16.7296 16.3899 16.6162 16.4649 16.521 16.5601C16.4258 16.6553 16.3508 16.7687 16.3005 16.8936L11.6795 28.3736C11.6067 28.5553 11.5889 28.7544 11.6283 28.9462C11.6676 29.1379 11.7624 29.3139 11.9008 29.4523C12.0392 29.5907 12.2152 29.6855 12.4069 29.7248C12.5987 29.7641 12.7978 29.7463 12.9795 29.6736L24.4595 25.0526C24.5844 25.0023 24.6978 24.9273 24.793 24.8321C24.8882 24.7369 24.9632 24.6235 25.0135 24.4986L29.6345 13.0186C29.7072 12.8369 29.725 12.6378 29.6857 12.446C29.6464 12.2543 29.5516 12.0783 29.4132 11.9399C29.2748 11.8015 29.0988 11.7067 28.907 11.6674C28.7153 11.6281 28.5162 11.6459 28.3345 11.7186ZM20.6575 22.6966C20.2619 22.6966 19.8752 22.5793 19.5463 22.3595C19.2174 22.1398 18.9611 21.8274 18.8097 21.462C18.6583 21.0965 18.6187 20.6944 18.6959 20.3064C18.7731 19.9185 18.9636 19.5621 19.2433 19.2824C19.523 19.0027 19.8793 18.8122 20.2673 18.735C20.6553 18.6579 21.0574 18.6975 21.4229 18.8488C21.7883 19.0002 22.1007 19.2566 22.3204 19.5855C22.5402 19.9144 22.6575 20.301 22.6575 20.6966C22.6575 21.227 22.4468 21.7357 22.0717 22.1108C21.6966 22.4859 21.1879 22.6966 20.6575 22.6966Z"
        fill={accent}
      />
      <path
        d="M20.5945 1.63361C16.8366 1.63361 13.1632 2.74794 10.0386 4.83568C6.91411 6.92343 4.47884 9.89082 3.04077 13.3626C1.60271 16.8344 1.22644 20.6547 1.95956 24.3403C2.69268 28.026 4.50226 31.4114 7.15945 34.0686C9.81665 36.7258 13.2021 38.5354 16.8878 39.2685C20.5734 40.0016 24.3937 39.6254 27.8655 38.1873C31.3373 36.7493 34.3047 34.314 36.3924 31.1894C38.4802 28.0649 39.5945 24.3915 39.5945 20.6336C39.5889 15.5962 37.5854 10.7667 34.0234 7.20472C30.4614 3.64273 25.6319 1.63916 20.5945 1.63361ZM34.5945 21.6336H37.5435C37.2953 25.7825 35.5353 29.6966 32.5964 32.6355C29.6574 35.5744 25.7434 37.3344 21.5945 37.5826V34.6336C21.5945 34.3684 21.4891 34.114 21.3016 33.9265C21.1141 33.739 20.8597 33.6336 20.5945 33.6336C20.3293 33.6336 20.0749 33.739 19.8874 33.9265C19.6998 34.114 19.5945 34.3684 19.5945 34.6336V37.5826C15.4456 37.3344 11.5315 35.5744 8.59259 32.6355C5.65365 29.6966 3.89365 25.7825 3.64548 21.6336H6.59448C6.8597 21.6336 7.11405 21.5282 7.30159 21.3407C7.48913 21.1532 7.59448 20.8988 7.59448 20.6336C7.59448 20.3684 7.48913 20.114 7.30159 19.9265C7.11405 19.739 6.8597 19.6336 6.59448 19.6336H3.64548C3.89365 15.4847 5.65365 11.5707 8.59259 8.63171C11.5315 5.69278 15.4456 3.93277 19.5945 3.68461V6.63361C19.5945 6.89882 19.6998 7.15318 19.8874 7.34071C20.0749 7.52825 20.3293 7.63361 20.5945 7.63361C20.8597 7.63361 21.1141 7.52825 21.3016 7.34071C21.4891 7.15318 21.5945 6.89882 21.5945 6.63361V3.68461C25.7434 3.93277 29.6574 5.69278 32.5964 8.63171C35.5353 11.5707 37.2953 15.4847 37.5435 19.6336H34.5945C34.3293 19.6336 34.0749 19.739 33.8874 19.9265C33.6998 20.114 33.5945 20.3684 33.5945 20.6336C33.5945 20.8988 33.6998 21.1532 33.8874 21.3407C34.0749 21.5282 34.3293 21.6336 34.5945 21.6336Z"
        fill={primary}
      />
    </>
  ),
  {
    viewBox: "0 0 40 40",
  },
);
